import styled from 'styled-components'

export default styled.div`
  height: 60px;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  .black {
    background: ${(props) => props.theme.darkBackground};
    &:after {
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0px;
      left: 0px;
      content: '';
      display: block;
      background-image: linear-gradient(
        90deg,
        rgba(117, 117, 117, 0.1) 0,
        rgba(117, 117, 117, 0.35) 20%,
        rgba(117, 117, 117, 0.35) 60%,
        rgba(117, 117, 117, 0.1)
      );
    }
  }
  .camera-name {
    font-family: 'Prompt-Medium', sans-serif;
    font-size: 14px;
    font-style: normal;
    font-stretch: normal;
    letter-spacing: normal;
    color: white;
    padding-left: 20px;
    max-width: 200px;
  }
  .camera-dot-status {
    width: 10px;
    height: 10px;
    border-radius: 100%;
    margin-left: 15px;
    &.active {
      background: ${(props) => props.theme.onlineCamera};
    }
    &.inactive {
      background: ${(props) => props.theme.inactiveCamera};
    }
    &.offline {
      background: ${(props) => props.theme.offlineCamera};
    }
  }
  &.clicked {
    border-right: 5px solid ${(props) => props.theme.jadeGreen};
    background: rgba(255, 255, 255, 0.1);
  }
  &:hover {
    cursor: pointer;
    background: rgba(255, 255, 255, 0.1);
  }
`
