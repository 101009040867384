/* eslint-disable multiline-ternary */
/* eslint-disable no-unused-vars */
import React from 'react'
import PropTypes from 'prop-types'
import SkeletonLoading from '../SkeletonLoading/Loadable'

import CameraSubLeftBarStyled from './styledComponent'

class CameraSubLeftBar extends React.Component {
  getContent() {
    const { cameraName, cameraStatus, isLoading } = this.props
    const content = isLoading ? (
      <SkeletonLoading className="black" />
    ) : (
      <>
        <span className="camera-name prevent-text-overflow">{cameraName}</span>
        <div className={'camera-dot-status ' + cameraStatus.toLowerCase()}>&nbsp;</div>
      </>
    )
    return content
  }

  render() {
    let clickedClassName = this.props.isClicked ? ' clicked' : ''
    return (
      <CameraSubLeftBarStyled className={clickedClassName} onClick={() => this.props.onClick()}>
        {this.getContent()}
      </CameraSubLeftBarStyled>
    )
  }
}

CameraSubLeftBar.propTypes = {
  cameraName: PropTypes.string,
  cameraStatus: PropTypes.string,
  isClicked: PropTypes.bool,
  onClick: PropTypes.func,
  isLoading: PropTypes.bool
}

export default CameraSubLeftBar
